<template>
  <div class="customer-field">
    <v-autocomplete
      class="customer-autocomplete-field"
      clearable
      hide-no-data
      hide-details
      item-text="text"
      item-value="id"
      prepend-icon="stem-person"
      :value="value"
      :label="$t('complaints.caller')"
      :items="items"
      :loading="isLoading"
      :searchInput.sync="searchInput"
      :readonly="!!value"
      :filter="onFilterValue"
      @input="onChange"
      @click:clear="onClearButtonClick"
      @update:search-input="onSearchInputChange"
    />
  </div>
</template>

<script>
import CrmCustomersService from "@/services/CrmCustomersService";

export default {
  name: "CustomerField",
  props: {
    value: { type: Object },
    returnObject: { type: Boolean, default: false },
    autoselectSingleItem: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: false,
      searchInput: null,
      items: [],
    };
  },
  methods: {
    onChange(newValue) {
      const customer = this.items.find((item) => item.id === newValue);

      if (this.returnObject) {
        this.$emit("input", customer);
      } else {
        this.$emit("input", customer.id);
      }
    },
    onSearchInputChange(newValue) {
      if (newValue && !this.value) {
        this.searchCustomers(newValue);
      } else if (!newValue) {
        this.items = [];
      }
    },
    onClearButtonClick() {
      this.items = [];
      this.$emit("input", null);
    },
    onFilterValue(item, queryText, itemText) {
      return queryText ? itemText.localeCompare(queryText, "pl") : false;
    },
    searchCustomers(filter) {
      this.isLoading = true;

      CrmCustomersService.GetCrmCustomersAutocomplete(filter)
        .then((response) => {
          this.items = response;

          if (this.autoselectSingleItem && response.length === 1) {
            if (this.returnObject) {
              this.$emit("input", response[0]);
            } else {
              this.$emit("input", response[0].id);
            }
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style scoped lang="scss">
.customer-autocomplete-field {
  margin: 0;
  padding: 0;
}
</style>
