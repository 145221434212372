import axios, { paramsSerializer } from "../plugins/http";
import WithUser from "../utils/WithUser";

const apiUrl = "api/crmcustomers";

export default {
  GetCrmCustomers(params) {
    return axios.get(apiUrl, {
      params,
      paramsSerializer,
    });
  },
  GetAllCrmCustomers() {
    return axios.get(`${apiUrl}/all`);
  },
  GetCrmTypes() {
    return axios.get(`${apiUrl}/types`);
  },
  GetDepartments(departmentId) {
    return axios.get(`${apiUrl}/departments`, {
      params: { departmentId },
    });
  },
  GetCrmGroups(types = []) {
    return axios.get(`${apiUrl}/groups`, {
      params: { types },
      paramsSerializer,
    });
  },
  GetCrmCustomersAutocomplete(searchParams) {
    if (searchParams) {
      return axios.get(`${apiUrl}/search?search=${searchParams}`);
    }

    return axios.get(`${apiUrl}/search`);
  },
  GetCrmCustomerDetails(id) {
    return axios.get(`${apiUrl}/${id}`);
  },
  UpdateCrmCustomer(contact) {
    const contactToSend = {
      ...contact,
      emails:
        contact.emails &&
        contact.emails[0] &&
        contact.emails[0].text.trim() === ""
          ? []
          : contact.emails,
      phones:
        contact.phones &&
        contact.phones[0] &&
        contact.phones[0].text.trim() === ""
          ? []
          : contact.phones,
    };

    return axios.put(`${apiUrl}/${contact.id}`, WithUser(contactToSend));
  },
  UpdateCrmCustomerForComplaint(contact) {
    const contactToSend = {
      ...contact,
      emails:
        contact.emails &&
        contact.emails[0] &&
        contact.emails[0].text.trim() === ""
          ? []
          : contact.emails,
      phones:
        contact.phones &&
        contact.phones[0] &&
        contact.phones[0].text.trim() === ""
          ? []
          : contact.phones,
    };

    return axios.put(
      `${apiUrl}/${contact.id}/forComplaint`,
      WithUser(contactToSend)
    );
  },
  CheckIsMediumTaken(value, channelType, ignoredUserId) {
    return axios.get(`${apiUrl}/isMediumTaken`, {
      params: { value, channelType, ignoredUserId },
    });
  },
  deleteCrmCustomer(customerIds) {
    return axios.delete(`${apiUrl}`, {
      data: WithUser({ customerIds }),
    });
  },
  GetCrmCustomerForUser(id) {
    return axios.get(`${apiUrl}/crmCustomerForUser/${id}`);
  },
  GetCrmCustomersAdvancedSearch(params) {
    return axios.get(`${apiUrl}/search/advanced`, { params, paramsSerializer });
  },
  async GetCrmCustomersBasicSearch(
    searchValue,
    pageNumber = 1,
    pageSize = 200
  ) {
    const response = await axios.get(
      `${apiUrl}/basic/search?search=${searchValue}&pagenumber=${pageNumber}&pagesize=${pageSize}`
    );
    return response.data;
  },
};
